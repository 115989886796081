 .sld-g{
   height: 500px;
 }

 .sld-m{
  height: 120px;
}
 .swiper-slide {
    background-position: center;
    background-size: cover;
      width: 850px;
      height: 500px;
  }
  
  @media (max-width: 768px) {
    .swiper-slide {
      width: 250px;
      height: 350px;
    }
    .sld-g{
      width: 250px;
      height: 350px;
    }
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .container {
    position: relative;
    width: 100%;
    display: block;
  }
  
  .container .swiper-container {
    
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
    padding-bottom: 3vw;
  }
  
  @media (max-width: 768px) {
    .container .swiper-container {
      padding-bottom: 8vw;
    }
  }
  
  .swiper-pagination-fraction {
    bottom: 0;
  }
  
  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 5px;
  }
  .swiper-pagination-bullet {
    
    background: #fff;
    opacity: .5;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: rgb(20, 133, 240);
}


.swiper-logo-slide{
  background-position: center;
    background-size: cover;
    width: 850px !important;
    height: 120px !important;
}


.slick-prev:before, .slick-next:before {
  color: #5f5f5f; 
}
.slick-dots {
  bottom: -6px;
}
.slick-dots li button:before {
  font-size: 9px;
}
.slick-dots li.slick-active button:before {
  color: rgb(20, 133, 240);
}
.swiper-container {
  z-index: 0;
}