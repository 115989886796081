/*----------- CARACTERISTICAS FUENTES ----------
sobre tamaños y fuentes:
-Frases en portadas de home y fondo azul: 20
-Menú: 13
-Títulos de portadas de cada página: 24
-Subtítulos: mayúscula 20
-Párrafos: 18 
-Botones: 13
-Gráficos: 10-12 
-Footer: 11
. Mensaje extra de contacto: 20
--------------------------------------*/

/*---------------------COLORES -----------------
AZUL - #005A91
GRIS OSCURO - #646464
GRIS CLARO - #E6E6E6
VERDE - #2E9D49
BLANCO - #FFFFFF
------------------------------------------------*/

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";



@font-face {
  font-family: 'Marta-Medium';
  src: url('fonts/Marta-Medium.otf');
}
@font-face {
  font-family: 'Marta-Light';
  src: url('fonts/Marta-Light.otf');
}
@font-face {
  font-family: 'Marta-Bold';
  src: url('fonts/Marta-Bold.otf');
}

@font-face {
  font-family: 'Marta-Ultra';
  src: url('fonts/Marta-Ultra.otf');
}
@font-face {
  font-family: 'Marta-Demi';
  src: url('fonts/Marta-Demi.otf');
}
@font-face {
  font-family: 'Marta-Book';
  src: url('fonts/Marta-Book.otf');
}
.Ma-light{
  font-family: 'Marta-Light';
}

.Ma-med{
  font-family: 'Marta-Medium';
}

.Ma-book{
  font-family: 'Marta-Book';
}
.Ma-ultra{
  font-family: 'Marta-Ultra';
}

.Ma-demi{
  font-family: 'Marta-Demi';
}
body {
  background-color: #323232;
}
.bt-margen{
  bottom: 0;
  top: 40rem;
  left: -2rem;
}
.menu li a, .menu-b li a {
  position: relative;
  display: block;
}
.menu li a::before, .menu-b li a::before  {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}
.menu li a::before {
  background: linear-gradient(to right, #ffffff, #ffffff, #ffffff);
}
.menu-b li a::before  {
  background: linear-gradient(to right, #000000, #000000, #000000);
}
.menu li a:hover::before , .menu-b li a:hover::before{
  transform: scaleX(1);
}
.menu[data-animation="center"] li a::before, .menu-b[data-animation="center"] li a::before {
  transform-origin: center;
}
/*-------------- CONFIGURACION GENERAL ---------------------*/
.margin-parrafo{
  margin-left: 210px;
  margin-right: 210px;
}
.text-azul{
  color: #005A91;
}
.text-portada{
  font-size: 50px;
  font-weight: 100;
  width: 30%;
}
.text-parrafo{
  font-family: "Martha-Light";
  font-size: x-large;
  font-weight: 100;
}
.text-mision{
  font-size: 25px;
  font-weight: 100;
}
.img-fondo{
  background-image: url('./images/fondo-vision.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover ;
  width: 100%;
  height: 70vh;
}
.navbar.active{
  background-color: white;
  color: #000 !important;
  z-index: 1030;
  transition: 0.5s;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}
.navbar.active ul li a{
  color: #000 !important;
}
.navbar{
  background-color: #0000;
  position: fixed;
  width: 100%;
  z-index: 1030;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  display: flex !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.2rem;
  padding-bottom: 0;
  margin-bottom: 0.75rem;
  transition: 0.5s;
}
.border1{
  border: 1px solid #005A91;
}
.border2{
  border: 1px solid white;
}

.foot-bg{
  background-color: #646464;
}
.slick-prev, .slick-next {
  top: 40% !important;
}
/*------------------------ TIME LINE ---------------------------*/
.vertical-timeline-element-content p {
  margin: 0!important;
  line-height: 0.98!important;
  font-weight: 100!important;
  text-align: justify!important;
}
.vertical-timeline--two-columns .vertical-timeline-element-content {
  padding: 10px 24px!important;
}
.vertical-timeline-element-date{
  color: #000;
  font-weight: 400!important;
}
.vertical-timeline-element-date{
  font-family: "Martha-Light"!important;
}
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  top: -4px!important;
  font-size: 20px!important;
}
.bg-md{
  background-color: #005A91;
}
.boton-silver:hover{
  background-color: #646464;
}

.fs-9{
  font-size: 0.9rem;
}
.bg-silver{
  background-color: #646464;
}
.txt-md{
  color: #005A91;
}
.txt-silver{
  color: #646464;
}

.Rombo{
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 20em;
  width: 20em;
  font-weight: 900;
  color: rgb(155, 155, 155);
}
.R01{
  background-image: url('./images/NUEVO/R01.png') ;
}
.R02{
  background-image: url('./images/NUEVO/R02.png') ;
}
.R03{
  background-image: url('./images/NUEVO/R03.png') ;
}
.R04{
  background-image: url('./images/NUEVO/R04.png') ;
}
.R05{
  background-image: url('./images/NUEVO/R05.png') ;
}
.R06{
  background-image: url('./images/NUEVO/R06.png') ;
}

.log-green{
  filter: invert(48%) sepia(89%) saturate(675%) hue-rotate(96deg) brightness(143%) contrast(53%);
}
.vertical-timeline-element-content{
  box-shadow: none!important;
}
.vertical-timeline::before {
  background: #2E9D49!important;
}


.slick-dots{
  display: none!important;
}
.fondo-banner{
  height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*------------------SERVICIOS--*/
.img-servicios{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover ;
  height: 30vh;
}
.img-servicio-uno{
  background-image: url('./images/servicio-1.webp');
}
.img-servicio-dos{
  background-image: url('./images/servicio-2.webp');
}
.img-servicio-tres{
  background-image: url('./images/servicio-3.webp');
}
.img-servicio-cuatro{
  background-image: url('./images/servicio-4.webp');
}
.text-subtitulo{
  font-size: 32px;
  padding-top: 70px;
}

/*-------MENU--------*/
.change {
  background-color : red !important;
}
/*---------------INICIO----------*/
.img-banner-principal{
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  height: 100vh;
}
.p-top-100{
  padding-top: 35rem;
}
.img-inicio-descripcion{
  background-image: url('./images/portada-6.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  height: 40vh;
}
/*--------------NAVBAR----------*/
.paddings{ 
padding-left: 1.5rem;
padding-right: 1.5rem;
padding-top: 1.2rem;
padding-bottom: 0;
margin-bottom: 0.75rem;
}
/*------------- FOOTER--------------*/
.z-index-Mod{
  z-index: 9999;
}
/*------------- MEDIA QUERY--------------*/

/*--------------------------------*/
@media screen and (min-width: 1920px) and (max-width: 3120px) {
  .xl-top-neg{
    margin-top: -150px;
  }
}
@media screen and (width: 1440px) {
  .img-fondo {
    height: auto;
  }
}
@media screen and (min-width: 1092px) and (max-width: 1570px) {
  .bt-margen {
    bottom: 0;
    top: 30rem;
    left: 2.05rem;
  }
  .navbar {
    padding-top: 0.75rem;
  }
  .text-mision{
    font-size: 20px;
    font-weight: 100;
  }
  .p-top-100 {
    padding-top: 27rem;
  }
}

@media screen and (min-width: 1092px) and (max-width: 1280px) {
  .bt-margen {
    bottom: 0;
    top: 30rem;
    left: 0rem;
  }
  .navbar {
    padding-top: 0.75rem;
  }
  .text-mision{
    font-size: 20px;
    font-weight: 100;
  }
  .img-fondo{
    height: auto;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
}

@media only screen and (min-width : 1200px) and (orientation: landscape) {

}
@media screen and (width:1024px) {
  .bt-margen {
    top: 60rem;
    left: 2rem;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
  .img-fondo{
    height: auto;
  }
  .fondo-banner {
    height: 40vh;
  }
  .xs-p-0{
    padding: 0;
  }
  .p-top-100 {
    padding-top: 34rem;
  }
  .margin-parrafo {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (min-width:800px) and (max-width: 1000px) {
  .img-fondo{
    height: auto;
  }
  .margin-parrafo {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width : 992px) {
  .img-fondo{
    height: auto;
  }
  .fondo-banner{
    height: 30vh;
  }
  .xs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xs-w-50{
    width: 65%;
  }
  .xs-flex{
    display: flex;
  }
  .xs-d-block{
    display: block!important;
  }
  .margin-parrafo {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width : 992px) and (orientation: landscape){
  .img-fondo{
    height: auto;
  }
  .fondo-banner{
    height: 100vh;
  }
  .xs-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xs-w-50{
    width: 65%;
  }
  .xs-flex{
    display: flex;
  }
  .xs-d-block{
    display: block!important;
  }
  .p-top-100 {
    padding-top: 10rem;
  }
  .xs-text-size-2{
    font-size: 1.3rem!important;
    line-height: 1.125rem;
  }
  .text-portada {
    font-size: 22px;
    width: 90%;
  }
  .img-servicios {
    height: auto;
  }
}
@media only screen and (width : 879px) {

}
@media screen and (width:768px) {
  .bt-margen {
    left: 2rem;
  }
  .img-fondo{
    height: auto;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
}
@media only screen and (width :751px){

}
@media screen and (max-width: 640px) {
  .xs-d-block{
    display: block!important;
  }
  .fondo-banner{
    height: 30vh;
  }
  .xs-p-0{
    padding: 0;
  }
  .xs-mt-5{
    margin-top: 24px;
  }
  .xs-text-size{
    font-size: 1.35rem;
  }
  .xs-text-size-2{
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  .xs-text-size-3{
    font-size: 1.80rem;
  }
  .xs-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xs-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xs-px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
  }
  .xs-w-50{
    width: 65%;
  }
  .xs-mt-4{
    margin-top: 4rem;
  }
  .xs-h-auto{
    height: auto;
  }
  .xs-w-auto{
    width: auto;
  }
  .xs-flex{
    display: flex;
  }
  .xs-mr-20{
    margin-right: 20px;
  }
  .bt-margen {
    top: 20rem;
    left: 1rem;
  }
  .text-portada {
    font-size: 18px;
    width: 90%;
  }
  .xs-left-6 {
    left: 1.9rem;
  }
  .xs-top-16 {
    top: 8rem;
  }
  .navbar {
    padding-top: 0.2rem;
  }
  .xs-w-40{
    width: 40%;
  }
  .img-fondo{
    height: auto;
  }
  .xs-order-2{
    order: 2;
  }
  .p-top-100 {
    padding-top: 20rem;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
}
@media only screen and (max-width: 640px) and (orientation: landscape){
  .xs-d-block{
    display: block!important;
  }
  .fondo-banner{
    height: 80vh;
  }
  .xs-p-0{
    padding: 0;
  }
  .xs-mt-5{
    margin-top: 24px;
  }
  .xs-text-size{
    font-size: 1.35rem;
  }
  .xs-text-size-2{
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  .xs-text-size-3{
    font-size: 1.80rem;
  }
  .xs-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xs-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xs-px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
  }
  .xs-w-50{
    width: 65%;
  }
  .xs-mt-4{
    margin-top: 4rem;
  }
  .xs-h-auto{
    height: auto;
  }
  .xs-w-auto{
    width: auto;
  }
  .xs-flex{
    display: flex;
  }
  .xs-mr-20{
    margin-right: 20px;
  }
  .bt-margen {
    top: 20rem;
    left: 1rem;
  }
  .text-portada {
    font-size: 20px;
    width: 90%;
  }
  .xs-left-6 {
    left: 1.9rem;
  }
  .xs-top-16 {
      top: 4rem;
  }
  .navbar {
    padding-top: 0.2rem;
  }
  .xs-w-40{
    width: 40%;
  }
  .img-fondo{
    height: auto;
  }
  .xs-order-2{
    order: 2;
  }
  .p-top-100 {
    padding-top: 9rem;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
}
@media only screen and (max-width : 500px) and (orientation: landscape){
  .xs-d-block{
    display: block;
  }
  .fondo-banner{
    height: 30vh;
  }
  .xs-p-0{
    padding: 0;
  }
  .xs-mt-5{
    margin-top: 24px;
  }
  .xs-text-size{
    font-size: 1.35rem;
  }
  .xs-text-size-2{
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  .xs-text-size-3{
    font-size: 1.80rem;
  }
  .xs-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xs-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xs-px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
  }
  .xs-w-50{
    width: 65%;
  }
  .xs-mt-4{
    margin-top: 4rem;
  }
  .xs-h-auto{
    height: auto;
  }
  .xs-w-auto{
    width: auto;
  }
  .xs-flex{
    display: flex;
  }
  .xs-mr-20{
    margin-right: 20px;
  }
  .bt-margen {
    top: 20rem;
    left: 1rem;
  }
  .text-portada {
    font-size: 18px;
    width: 90%;
  }
  .xs-left-6 {
    left: 1.9rem;
  }
  .xs-top-16 {
      top: 4rem;
  }
  .navbar {
    padding-top: 0.2rem;
  }
  .xs-w-40{
    width: 40%;
  }
  .img-fondo{
    height: auto;
  }
  .xs-order-2{
    order: 2;
  }
  .p-top-100 {
    padding-top: 20rem;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
}
@media screen and (max-width: 380px) {
  .xs-d-block{
    display: block!important;
  }
  .fondo-banner{
    height: 30vh;
  }
  .xs-p-0{
    padding: 0;
  }
  .xs-mt-5{
    margin-top: 24px;
  }
  .xs-text-size{
    font-size: 1.35rem;
  }
  .xs-text-size-2{
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  .xs-text-size-3{
    font-size: 1.80rem;
  }
  .xs-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xs-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xs-px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
  }
  .xs-w-50{
    width: 65%;
  }
  .xs-mt-4{
    margin-top: 4rem;
  }
  .xs-h-auto{
    height: auto;
  }
  .xs-w-auto{
    width: auto;
  }
  .xs-flex{
    display: flex;
  }
  .xs-mr-20{
    margin-right: 20px;
  }
  .bt-margen {
    top: 20rem;
    left: 1rem;
  }
  .text-portada {
    font-size: 18px;
    width: 90%;
  }
  .xs-left-6 {
    left: 1.9rem;
  }
  .xs-top-16 {
    top: 6rem;
  }
  .navbar {
    padding-top: 0.2rem;
  }
  .xs-w-40{
    width: 40%;
  }
  .img-fondo{
    height: auto;
  }
  .xs-order-2{
    order: 2;
  }
  .p-top-100 {
    padding-top: 20rem;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
}
@media screen and (max-width: 320px) {
  .xs-d-block{
    display: block;
  }
  .fondo-banner{
    height: 30vh;
  }
  .xs-p-0{
    padding: 0;
  }
  .xs-mt-5{
    margin-top: 24px;
  }
  .xs-text-size{
    font-size: 1.35rem;
  }
  .xs-text-size-2{
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  .xs-text-size-3{
    font-size: 1.80rem;
  }
  .xs-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xs-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xs-px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
  }
  .xs-w-50{
    width: 65%;
  }
  .xs-mt-4{
    margin-top: 4rem;
  }
  .xs-h-auto{
    height: auto;
  }
  .xs-w-auto{
    width: auto;
  }
  .xs-flex{
    display: flex;
  }
  .xs-mr-20{
    margin-right: 20px;
  }
  .bt-margen {
    top: 20rem;
    left: 1rem;
  }
  .text-portada {
    font-size: 18px;
    width: 90%;
  }
  .xs-left-6 {
    left: 1.9rem;
  }
  .xs-top-16 {
      top: 4rem;
  }
  .navbar {
    padding-top: 0.2rem;
  }
  .xs-w-40{
    width: 40%;
  }
  .img-fondo{
    height: auto;
  }
  .xs-order-2{
    order: 2;
  }
  .p-top-100 {
    padding-top: 20rem;
  }
  .xs-pl-1{
    padding-left: 1.5rem;
  }
}


@media screen and (min-width: 1092px) and (max-width: 1570px) and (orientation: landscape){

}
@media screen and (width:1024px) and (orientation: landscape){
  .bt-margen {
    bottom: 0;
    top: 22rem;
    left: 2rem;
  }
  .ls-text-5xl {
    font-size: 2rem;
    line-height: 1;
  }
  .ls-py-28 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media screen and (min-width:800px) and (max-width: 1000px) and (orientation: landscape){

}
@media only screen and (width : 879px) and (orientation: landscape){

}
@media only screen and (min-width : 768px) and (orientation: landscape){

}
@media screen and (width:768px) and (orientation: landscape){

}
@media only screen and (max-width : 768px) and (orientation: landscape) {
  .xs-flex{
    display: flex;
  }
}
@media only screen and (width :786px) and (orientation: landscape) {

}
@media only screen and (max-width : 500px) and (orientation: landscape) {

}



